@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

.contact::after {
  z-index: 5;
  position: absolute;
  top: 98.1%;
  left: 50%;
  margin-left: -50px;
  content: '';
  width: 0;
  height: 0;
  border-top: solid 50px;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
  @apply border-t-base-300;
}
